import { useTitle } from "./useTitle"
import { useUser } from "../auth"

export default function SetTitle({ title, fnTitle, children }) {
  const user = useUser()
  let pageTitle = title

  if (fnTitle) {
    pageTitle = fnTitle(user.display)
  }
  useTitle(pageTitle)
  
  return children
}
